import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanDeactivateGuardJoinus {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
 }

@Injectable({
  providedIn: 'root'
})
export class DeactivateGuardJoinusService implements CanDeactivate<CanDeactivateGuardJoinus>{

  constructor() { }

  canDeactivate(component: CanDeactivateGuardJoinus) {
    return component.canDeactivate() ;
  }
}
