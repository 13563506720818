import { Component, OnInit } from '@angular/core';
declare var $: any;
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription,timer } from 'rxjs';
import { NgwWowService } from 'ngx-wow';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(private router: Router, private wowService: NgwWowService) { 
    //  this.wowService.init();
  }
  ngAfterViewInit(){
    $(document).ready(function() {
    $(window).scroll(function (event) {
      var scroll = $(window).scrollTop();
      // if($('.line-mt').offset()){
      //   $('#line-set').toggleClass('line-ht-nanim',
      //   //add 'ok' class when div position match or exceeds else remove the 'ok' class.
      //    scroll >= $('.line-mt').offset().top-300
      //  );
      // }
    // if($('.line-mt-ht').offset()){
    //   $('#line-set3').toggleClass('line-ht-nanim',
    //   //add 'ok' class when div position match or exceeds else remove the 'ok' class.
    //    scroll >= $('.line-mt-ht').offset().top-300
    //  );
    // }
    // if($('.animate-left').offset()){
    //   $('#img1').toggleClass('widht80',
    //   //add 'ok' class when div position match or exceeds else remove the 'ok' class.
    //    scroll >= $('.animate-left').offset().top-200
    //  );
    // }
    // if($('.animte-right').offset()){
    //   $('#img2').toggleClass('widht80',
    //   //add 'ok' class when div position match or exceeds else remove the 'ok' class.
    //    scroll >= $('.animte-right').offset().top-200
    //  );
    // }
  
     
    // if($('.about-note').offset()){
    //   $('#ln-mission').toggleClass('mission-line',
    //   //add 'ok' class when div position match or exceeds else remove the 'ok' class.
    //    scroll >= $('.about-note').offset().top-100
    //  );
    // }

  });
  $(window).scroll();
  //trigger the scroll
});
   
  }
  ngOnInit() {
    // const source = timer(4000);
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    //   .subscribe(() => window.scroll(0, 0))
    //   source.subscribe(val => this.wowService.init());
       
  }
  reset() {
  //  this.wowService.init();
  }
}
