export class Section {
    id;
    title;
    subTitle;
    progerssBarVal;
    canSubmit;
    static currentSection;
    static currentSecIndex;

    constructor() {
    }

    static next() {
        if (this.currentSecIndex < 3) {
            this.currentSecIndex++;
            this.setSection();
        }
    }

    static reset() {
        this.currentSecIndex = 0;
        this.setSection();
    }


    static setSection() {
        this.currentSection = this.sectionList[this.currentSecIndex] || this.sectionList[0];
    }


    static sectionList: Array<Section> = [
        {
            id: 1,
            title: "Review The Modern Ape’s Community Requirements",
            subTitle: "We’re so glad you’re applying! Before we proceed, make sure you’ve read through our guidelines carefully.",
            progerssBarVal: 1,
            canSubmit: false
        },
        {
            id: 2,
            title: "Information",
            subTitle: "Help us get to know you better. Your information will not be shared with any third parties and will remain secure in our database.",
            progerssBarVal: 2,
            canSubmit: false
        },
        {
            id: 3,
            title: "Work & Portfolio",
            subTitle: "Pick your Community and tell us about your business.",
            progerssBarVal: 3,
            canSubmit: false
        },
        {
            id: 4,
            title: "Additional Information",
            subTitle: "Just making sure we’re covering all bases.",
            progerssBarVal: 4,
            canSubmit: true
        }
    ];
}
