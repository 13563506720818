export class Creator{
    private _name: string;
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    private _about: string;
    public get about(): string {
        return this._about;
    }
    public set about(value: string) {
        this._about = value;
    }
    private _profile_photo: string;
    public get profile_photo(): string {
        return this._profile_photo;
    }
    public set profile_photo(value: string) {
        this._profile_photo = value;
    }
    private _content: string;
    public get content(): string {
        return this._content;
    }
    public set content(value: string) {
        this._content = value;
    }
}