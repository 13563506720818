import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { CommunityMember } from '../../../../models/CommunityMember';
import { TeamService } from '../../../../services/team.service';

@Component({
  selector: 'app-founding-community',
  templateUrl: './founding-community.component.html',
  styleUrls: ['./founding-community.component.scss']
})
export class FoundingCommunityComponent implements OnInit {

  communityMemberList:Array<CommunityMember>

  constructor(private teamService:TeamService ) {
    this.communityMemberList = this.teamService.communityMemberList;
   
   }
   ngAfterViewInit(){
    // this.wowService.init();
   }
  ngOnInit(): void {
  }
  // reset() {
  //   this.wowService.init();
  // }
}
