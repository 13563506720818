import { Component, OnInit } from '@angular/core';
import { slideInAnimation } from '../../route-animation';
import { Router, NavigationEnd } from '@angular/router';
import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  animateChild
} from '@angular/animations';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],

})
export class InfoComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    // this.router.events.subscribe((evt) => {
    //     if (!(evt instanceof NavigationEnd)) {
    //         return;
    //     }
    //     window.scrollTo(0, 0)
    // });
}

}
