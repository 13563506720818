import { Injectable } from '@angular/core';
import { Post } from '../models/Post';
import { GatewayService } from './gateway.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  private _postList = new BehaviorSubject<Array<Post>>([]);

  set postList(data: Array<Post>) {
    this._postList.next(data);
  }


  getPostList(): Observable<any> {
    return this._postList.asObservable();
  }

  constructor(private gatewayService: GatewayService) {
    let list = []
    for (let index = 1; index <= 5; index++) {
      let post: Post = new Post();
      post.name = "Name goes here";
      post.profile_photo = "../../assets/images/instagram4.png";
      post.image = "../../assets/images/instagram2.png";
      post.content = `Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry. standard dummy text ever
                      since the 1500s....`;
      post.publish_at = `May ${index}, 2020`;
      list.push(post)
    }
    
    this.postList = list;

    this.getPostFromServer();
  }

  getPostFromServer() {
    this.gatewayService.getInstagramPost().subscribe(res => {
      let data = res['data'];
      try {
        let list = [];
        data.map(item => {
          let post = new Post;
          post.name = item.author;
          post.profile_photo = item.profile_image;
          post.publish_at = item.publish_date;
          post.image = item.post_image;
          post.content = item.description;
          list.push(post);
        })

        this.postList = list;
      } catch (e) {

      }
    })
  }


}
