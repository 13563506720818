import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgwWowService } from 'ngx-wow';
import { Founder } from '../../../models/Founder';
import { FounderService } from '../../../services/founder.service'
@Component({
  selector: 'app-founder-note',
  templateUrl: './founder-note.component.html',
  styleUrls: ['./founder-note.component.scss']
})
export class FounderNoteComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: true,
    navSpeed: 700,
    navText: [' <fa-icon [icon]="faAngleLeft"></fa-icon>', ' <fa-icon [icon]="faAlignRight"></fa-icon>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },

  }

  founderList: Array<Founder> = [];

  constructor(private founderService:FounderService,private wowService: NgwWowService) { 
    this.founderList = this.founderService.founderList
    this.wowService.init();
  }
 

  ngOnInit(): void {
  }

}


