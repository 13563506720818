export class Founder {
  private _name;
  public get name() {
    return this._name;
  }
  public set name(value) {
    this._name = value;
  }
  private _quote;
  public get quote() {
    return this._quote;
  }
  public set quote(value) {
    this._quote = value;
  }
  private _quoteAuther;
  public get quoteAuther() {
    return this._quoteAuther;
  }
  public set quoteAuther(value) {
    this._quoteAuther = value;
  }
  private _designation;
  public get designation() {
    return this._designation;
  }
  public set designation(value) {
    this._designation = value;
  }
  private _image;
  public get image() {
    return this._image;
  }
  public set image(value) {
    this._image = value;
  }
  private _note;
  public get note() {
    return this._note;
  }
  public set note(value) {
    this._note = value;
  }
}
