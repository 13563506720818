import { Component, OnDestroy } from '@angular/core';
import { slideInAnimation } from './route-animation';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription, timer } from 'rxjs';
import { NgwWowService } from 'ngx-wow';
import { style, state, animate, transition, trigger } from '@angular/animations';
import { LoaderService } from './services/loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 1 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})

export class AppComponent {
  private wowSubscription: Subscription;
  private pageLoaderSubscription: Subscription;
  private gatewayLoaderSubscription: Subscription

  isPageLoaderLoading = false
  isGatewayLoaderLoading = false;

  constructor(private router: Router, private wowService: NgwWowService, private loaderService: LoaderService) {
    this.router.events.subscribe((event: NavigationStart | NavigationEnd | NavigationCancel | NavigationError) => {
      if (event.url == '/m/home' || event.url == '/m' || event.url == '/') {
        if (event instanceof NavigationStart) {
          this.loaderService.isPageLoaderLoading = true;
        } else if (event instanceof NavigationEnd) {
          timer(4450).subscribe(x => this.loaderService.isPageLoaderLoading = false);
        } else if (event instanceof NavigationCancel) {
          this.loaderService.isPageLoaderLoading = false;
        } else if (event instanceof NavigationError) {
          this.loaderService.isPageLoaderLoading = false
        }
      }
    })

    this.pageLoaderSubscription = this.loaderService.pageLoader().subscribe(res => {
      this.isPageLoaderLoading = res
    })

    this.gatewayLoaderSubscription = this.loaderService.gatewayLoader().subscribe(res => {
      this.isGatewayLoaderLoading = res
    })
  }

  onlyone() {
    this.wowService.init();

  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => window.scroll(0, 0))
  }


  ngOnDestroy() {
    this.pageLoaderSubscription.unsubscribe();
    this.gatewayLoaderSubscription.unsubscribe();
    this.wowSubscription.unsubscribe();
  }
}
