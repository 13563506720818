import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Section } from '../Section';
import { JoinUsFormService } from '../../services/join-us-form.service';
import { ToastrService } from 'ngx-toastr';
import { regexExperation } from './regexExperation';

@Component({
  selector: 'app-basic-info-form',
  templateUrl: './basic-info-form.component.html',
  styleUrls: ['./basic-info-form.component.scss']
})
export class BasicInfoFormComponent implements OnInit {
  businessRequirements = [
    { key: "Business Network & Resources", checked: false, id: 1, disabled: false },
    { key: "Professional Growth", checked: false, id: 2, disabled: false },
    { key: "Reach & Visibility", checked: false, id: 3, disabled: false },
    { key: "Lead Generation", checked: false, id: 4, disabled: false },
    { key: "Marketing & Sales", checked: false, id: 5, disabled: false }
  ]
  isDisabled = false

  section = Section;
  basicInfoForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]*')]],
    lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]*')]],
    city: ['', Validators.required],
    email: ['', [Validators.required, Validators.email, , Validators.maxLength(70), Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
    tel: ['', [Validators.required, Validators.minLength, Validators.pattern('^((\\+91-?)|0)?[6-9]{1}[0-9]{9}$')]],
    businessName: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]*')]],
    industry: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]*')]],
    businessRequirements: ['', Validators.required],
  }, {
    updateOn: 'blur'
  });
  isSubmitted = false;
  maxDate: Date = this.getMaxDate();

  constructor(private fb: FormBuilder,
    private joinUsService: JoinUsFormService,
    private notificationService: ToastrService) {
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit() {
  }

  getMaxDate() {
    let now = new Date();
    return new Date(now.setFullYear(now.getFullYear() - 5));
  }

  validateBusinessRequirements(checked, value) {
    const index = this.businessRequirements.findIndex(br => br.key === value)

    this.businessRequirements[index].checked = checked

    if (this.businessRequirements.filter(br => br.checked).length === 2) {
      this.businessRequirements.forEach(br => {
        if (!br.checked) br.disabled = true
      })
    } else {
      this.businessRequirements.forEach(br => br.disabled = false)
    }
  }

  continue() {
    this.formIsSubmitted();
    if (!this.basicInfoForm.valid) {
      this.notificationService.warning("Please check your inputs")
      return;
    }
    this.joinUsService.updateFormValues(this.basicInfoForm.value);
    Section.next();
  }

  submit() {
    this.formIsSubmitted();
    if (!this.basicInfoForm.valid) {
      this.notificationService.warning("Please check your inputs")
      return;
    }

    this.joinUsService.submitDetails({ ...this.basicInfoForm.value, contactNo: this.basicInfoForm.value.tel, businessRequirements: this.businessRequirements.filter(br => br.checked).map(br => br.key) })
  }

  private formIsSubmitted() {
    this.isSubmitted = true;
  }
}
