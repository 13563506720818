import { Component, OnInit } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { NgwWowService } from 'ngx-wow';
declare var $: any;
import { TeamService } from '../../../../services/team.service'
import { CoreTeamMember } from '../../../../models/CoreTeamMember';
@Component({
  selector: 'app-core-team',
  templateUrl: './core-team.component.html',
  styleUrls: ['./core-team.component.scss']
})
export class CoreTeamComponent implements OnInit {

  coreTeamList:Array<CoreTeamMember>

  constructor(private teamService:TeamService) {
    this.coreTeamList = this.teamService.coreTeamList
   }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    $(window).on('scroll', function() {
      $(".comm-detail").each(function() {
        if (isScrolledIntoView($(this))) {
          $(this).find(".graph-line").addClass("graph-75");
          $(this).find(".graph-line-2").addClass("opacity");
        }
      });
    });
    
    function isScrolledIntoView(elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();
    
      var elemTop = $(elem).offset().top;
      var elemBottom = elemTop + $(elem).height();
    
      return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
    // this.wowService.init();
  }
 
}
