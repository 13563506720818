export class Post {
      private _name: string = "";
      public get name(): string {
            return this._name;
      }
      public set name(value: string) {
            this._name = value;
      }
      private _profile_photo: string = "";
      public get profile_photo(): string {
            return this._profile_photo;
      }
      public set profile_photo(value: string) {
            this._profile_photo = value;
      }
      private _image: string = "../../assets/images/instagram2.png";
      public get image(): string {
            return this._image;
      }
      public set image(value: string) {
            this._image = value;
      }
      private _content: string = "";
      public get content(): string {
            return this._content;
      }
      public set content(value: string) {
            this._content = value;
      }
      private _publish_at: string = "";
      public get publish_at(): string {
            return this._publish_at;
      }
      public set publish_at(value: string) {
            this._publish_at = value;
      }
}