import { Component, OnInit, HostListener, Inject, Renderer2, ElementRef, Directive, AfterContentInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import * as AOS from 'aos';
declare var $: any;
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  isFitTheBillVideoStarted = false;

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }

  ngOnInit(): void {
    // this.wowService.init();
  }

  reset() {
    this.wowService.init();
  }
  ngAfterViewInit() {
    $('.counter-count').each(function () {
      $(this).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 8000,
        easing: 'swing',
        step: function (now) {
          $(this).text(Math.ceil(now));
        }
      });
    });
    // window.onload = function () {
    //   setTimeout(function(){
    //     // $("#vid").get(0).play(); // note the [0] to access the native element
    // }, 10000); 
    //   }


    //     let audioPlayer = <HTMLVideoElement> document.getElementById(vid);

    // audioPlayer.play();
    if ($('body').scrollTop() > $('.expriance-section').position.top) {
      $('.right-content').addClass('selected');
    }
    var HomeComponent = this;

    $(document).ready(function () {
      function playDoYouFitSectionVideoOnceOnVisible() {
        try {
          var scroll = $(window).scrollTop();
          let top = $("#fitTheBillVideo").offset();
          let top_of_element = top['top'];
          let bottom_of_element = top['top'] + $("#fitTheBillVideo").outerHeight();
          let bottom_of_screen = scroll + $(window).innerHeight() - 200;
          let top_of_screen = scroll + 200

          if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
            $('#fitTheBillVideo').get(0).play();
            HomeComponent.isFitTheBillVideoStarted = true;
          }

        } catch (error) {
          // console.log(error)
        }
      }
      // ('#vid').play(10000);
      $(window).scroll(function (event) {
        if (!HomeComponent.isFitTheBillVideoStarted) {
          playDoYouFitSectionVideoOnceOnVisible();
        }

        var scroll = $(window).scrollTop();
        // if ($('.line-mt-htmwa').offset()) {
        //   $('#lin6').toggleClass('line-ht-100',
        //     scroll >= $('.line-mt-htmwa').offset().top - 200,
        //   );
        // }

        if ($('.line-mt-lnt').offset()) {
          $('#line7').toggleClass('line-ht-100',
            scroll >= $('.line-mt-lnt').offset().top - 100,
          );
        }
        if ($('.img-show-full').offset()) {
          $('#img-show').toggleClass('img-wt-100',
            scroll >= $('.img-show-full').offset().top - 400,
          );

        }
        if ($('.img-show-nt').offset()) {
          $('#img-sw-nt').toggleClass('img-wt-100',
            scroll >= $('.img-show-nt').offset().top - 400,
          );


        }
        //    if($('.nc1').offset()){

        //     $('#no1').toggleClass('counter-count',
        //     scroll >= $('.nc1').offset().top-300,

        //     );

        //  }
        //add 'ok' class when div position match or exceeds else remove the 'ok' class.


      });

    });
  }

}
