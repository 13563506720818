import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Section } from '../Section';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { JoinUsFormService } from '../../services/join-us-form.service';
import { ToastrService } from 'ngx-toastr';
import { DocumentInputHelper } from './DocumentInputHelper';

@Component({
  selector: 'app-portfolio-form',
  templateUrl: './portfolio-form.component.html',
  styleUrls: ['./portfolio-form.component.scss']
})
export class PortfolioFormComponent implements OnInit {

  section = Section;
  portfolioForm = this.fb.group({
    hub: ['', Validators.required],
    natureOfBussiness: [''],
    businessGoal: [''],
    brandName: ['',Validators.maxLength(100)],
    ownershipType: ['',Validators.pattern('[a-zA-Z-/() ]+')],
    websiteOrPortfolioURL: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})\\b([-a-zA-Z0-9@:%_+.~#?&//=]*)')],
    document: this.fb.array([]),
    instagramURL: ['', [Validators.maxLength(30),Validators.pattern('[a-zA-Z0-9_. ]+')]],
    twitterURL: ['', [Validators.maxLength(30),Validators.pattern('[a-zA-Z0-9_ ]+')]],
    facebookURL: [''],
    otherURL: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})\\b([-a-zA-Z0-9@:%_+.~#?&//=]*)')]
  });
  isSubmitted = false;
  @ViewChild('documentInput')
  documentInput: ElementRef;

  constructor(private fb: FormBuilder,
    private joinUsService: JoinUsFormService,
    private notificationService: ToastrService) { }

  ngOnInit() {
  }

  continue() {
    this.formIsSubmitted()
    if (!this.portfolioForm.valid) {
      this.notificationService.warning("Please check your inputs")
      return;
    }
    this.joinUsService.updateFormValues(this.portfolioForm.value);
    Section.next();

  }

  onChangeDocument(event) {
    new DocumentInputHelper(this, this.notificationService, this.fb).onChange(event);
  }

  onClickDocument(){
    new DocumentInputHelper(this, this.notificationService, this.fb).resetDocumentInput();
  }

  removePhoto(i) {
    this.document.removeAt(i);
  }

  get document(): FormArray {
    return this.portfolioForm.get('document') as FormArray;
  };

  private formIsSubmitted() {
    this.isSubmitted = true;
  }

}
