import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';

@Component({
  selector: 'app-art-india-company',
  templateUrl: './art-india-company.component.html',
  styleUrls: ['./art-india-company.component.scss']
})
export class ArtIndiaCompanyComponent implements OnInit {

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
   }

  ngOnInit(): void {
  }
  reset() {
    this.wowService.init();
  }

  redirect(link) {
    // this.document.location.href = link;
    
    window.open(link, "_blank");
  }
}
