import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
@Component({
  selector: 'app-ilaaka',
  templateUrl: './ilaaka.component.html',
  styleUrls: ['./ilaaka.component.scss']
})
export class IlaakaComponent implements OnInit {

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }

  ngOnInit(): void {
  }
  reset() {
    this.wowService.init();
  }
  redirect(link) {
    // this.document.location.href = link;

    window.open(link, "_blank");
  }
}
