import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Joinus } from '../models/Joinus';
import { GatewayService } from './gateway.service';
import { JoinUsRequest } from '../models/JoinUsRequest';
import { DatePipe } from '@angular/common';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class JoinUsFormService {

  private _isFormCompleted = new BehaviorSubject<boolean>(false);
  set isFormCompleted(data: boolean) {
    this._isFormCompleted.next(data);
  }

  payload: Joinus = new Joinus();

  constructor(private gatewayService: GatewayService,
    private datepipe: DatePipe,
    private loaderService:LoaderService,
    private toastr:ToastrService) { }

  updateFormValues(payload: Joinus) {
    this.payload = { ...this.payload, ...payload };
  }

  isCompleted(): Observable<any> {
    return this._isFormCompleted.asObservable();
  }

  reset() {
    this.payload = new Joinus();
    this.isFormCompleted = false;
  }

  submitDetails(data) {
    this.loaderService.isGatewayLoaderLoading = true

    this.gatewayService.sendJoinUsForm(data).subscribe(response => {
      this.isFormCompleted = true;
    },error=>{
      this.toastr.error("Something went wrong");
    },()=>{
      this.loaderService.isGatewayLoaderLoading = false
    });
  }

  send() {
    this.loaderService.isGatewayLoaderLoading = true
    let request = new JoinUsRequest;
    request.address = this.payload.address;
    request.ambition = [];
    try {
      for (const iterator in this.payload.ambition) {
        if (this.payload.ambition[iterator] == true) {
          request.ambition.push(iterator);
        }
      }
    } catch (e) {
      request.ambition = [];
      console.log(e)
    }
    request.brand_name = this.payload.brandName;
    request.business_goal = this.payload.businessGoal;
    request.comment = this.payload.note;
    request.creator_hub = this.payload.hub;
    request.dob = this.convertDate(this.payload.dateOfBirth);
    request.document = this.payload.document;
    request.email = this.payload.email;
    request.facebook = this.payload.facebookURL;
    request.gender = this.payload.gender;
    request.instagram = this.payload.instagramURL;
    request.name = this.payload.fullName;
    request.nature_of_business = this.payload.natureOfBussiness;
    request.ownership_type = this.payload.ownershipType;
    request.phone_number = this.payload.tel;
    request.social_media_other = this.payload.twitterURL;
    request.twitter = this.payload.twitterURL;
    request.website_url = this.payload.websiteOrPortfolioURL;

    this.gatewayService.sendJoinUsForm(request).subscribe(response => {
      this.isFormCompleted = true;
    },error=>{
      this.toastr.error("Something went wrong");
    },()=>{
      this.loaderService.isGatewayLoaderLoading = false
    });
  }

  private convertDate(date) {
    try {
      return this.datepipe.transform(date, 'yyyy-MM-dd');
    }
    catch (e) {
      return ""
    }
  }
}
