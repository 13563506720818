import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {


  private _isPageLoaderLoading = new BehaviorSubject<boolean>(false);

  set isPageLoaderLoading(data: boolean) {
    this._isPageLoaderLoading.next(data);
  } 

  private _isGatewayLoaderLoading = new BehaviorSubject<boolean>(false);

  set isGatewayLoaderLoading(data: boolean) {
    this._isGatewayLoaderLoading.next(data);
  } 

  constructor() { }

  pageLoader(): Observable<any> {
    return this._isPageLoaderLoading.asObservable();
  }

  gatewayLoader(): Observable<any> {
    return this._isGatewayLoaderLoading.asObservable();
  }
}
