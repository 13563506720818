import { Component, OnInit } from '@angular/core';
import { faChevronCircleRight} from '@fortawesome/free-solid-svg-icons';
declare var $: any;
import { NgwWowService } from 'ngx-wow';

@Component({
  selector: 'app-our-service',
  templateUrl: './our-service.component.html',
  styleUrls: ['./our-service.component.scss']
})
export class OurServiceComponent implements OnInit {
  faRight = faChevronCircleRight;


  constructor(private wowService: NgwWowService) { 
    this.wowService.init();
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    $(document).ready(function() {
    $(window).scroll(function (event) {
      var scroll = $(window).scrollTop();
      // if($('.line-mt-htm').offset()){
      //   $('#line-set4').toggleClass('ln-border-right',
      //   //add 'ok' class when div position match or exceeds else remove the 'ok' class.
      //    scroll >= $('.line-mt-htm').offset().top-150
      //  );
      // }
    // if($('.line-mt-htmw').offset()){
    //   $('#line1').toggleClass('ln-border-width',
    //   //add 'ok' class when div position match or exceeds else remove the 'ok' class.
    //    scroll >= $('.line-mt-htmw').offset().top-150
    //  );
    // }
     
  });
  $(window).scroll();
  //trigger the scroll
  $('#A_ID').click(function (e) { //#A_ID is an example. Use the id of your Anchor
    $('html, body').animate({
        scrollTop: $('#DIV_ID').offset().top - 20 //#DIV_ID is an example. Use the id of your destination on the page
    }, 'slow');
});
});
   
  }
  refresh(): void {
    window.location.reload();
}
  reset() {
    this.wowService.init();
  }
}
