import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { faCoffee, faAngleLeft, faAlignRight } from '@fortawesome/free-solid-svg-icons';
import { CreatorService} from '../../../services/creator.service'
import { NgwWowService } from 'ngx-wow';
@Component({
  selector: 'app-creators',
  templateUrl: './creators.component.html',
  styleUrls: ['./creators.component.scss']
})
export class CreatorsComponent implements OnInit {
  faCoffee = faCoffee;
  faAlignRight = faAlignRight;
  faAngleLeft = faAngleLeft;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    animateOut: 'bounceInRight',
    animateIn: 'bounceInRight',
    nav: true,
    navSpeed: 700,
    navText: [' <fa-icon [icon]="faAngleLeft"></fa-icon>', ' <fa-icon [icon]="faAlignRight"></fa-icon>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },

  }
  
  creatorList = [];
  constructor(private creatorService:CreatorService,private wowService: NgwWowService) {
    this.wowService.init();
   }

  ngOnInit(): void {
    this.creatorList = this.creatorService.creatorList
  }
  reset() {
    this.wowService.init();
  }

}
