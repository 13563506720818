import { Component, OnInit } from '@angular/core';
import { NavBar } from '../../models/NavBar';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  navBar: NavBar;

  constructor() {
    this.navBar = new NavBar();
  }
  ngAfterViewInit(){
    $("#toggle").click(function(){
      if(!$(".sidemenu-logo").hasClass("Anim-logo")){
        $(".sidemenu-logo").addClass("Anim-logo").removeClass("intro");
     }else if(!$(".sidemenu-logo").hasClass("intro")){
      $(".sidemenu-logo").addClass("intro").removeClass("Anim-logo");
     }
    });
    $(".sidemenu-link ul li a").click(function(){
      if(!$(".sidemenu-logo").hasClass("Anim-logo")){
        $(".sidemenu-logo").addClass("Anim-logo").removeClass("intro");
     }else if(!$(".sidemenu-logo").hasClass("intro")){
      $(".sidemenu-logo").addClass("intro").removeClass("Anim-logo");
     }
    });
  }

}
