import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { timer } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  number1 = 0;
  number2 = 0;
  number3 = 0;
  isStarted = false;

  constructor(private wowService: NgwWowService) { }

  ngOnInit() {
    var myComponent = this;
    $(document).ready(function () {
      function newFunction() {
        try {
          var scroll = $(window).scrollTop();
  
          let top = $("#stats").offset();
          console.log()
          let top_of_element = top['top'];
          let bottom_of_element = top['top'] + $("#stats").outerHeight();
          let bottom_of_screen = scroll + $(window).innerHeight() - 200;
          let top_of_screen = scroll + 200
  
          if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
            myComponent.startCounter()
          }
          
        } catch (error) {
        // console.log(error)
        }
      }
      newFunction();
      // ('#vid').play(10000);
      $(window).scroll(function (event) {
        newFunction();
      });
    })
  }
  startCounter() {
    if (!this.isStarted) {
      this.animateCountUp1(124);
      this.animateCountUp2(330);
      this.animateCountUp3(23);
      this.isStarted = true;
    }
  }

  animateCountUp1(toNumber) {

    // How long you want the animation to take, in ms
    const animationDuration = 2000;
    // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
    const frameDuration = 1000 / 32;
    // Use that to calculate how many frames we need to complete the animation
    const totalFrames = Math.round(animationDuration / frameDuration);
    // An ease-out function that slows the count as it progresses

    function easeOutQuad(t) {
      return t * (2 - t);
    }

    let frame = 0;
    const countTo = toNumber
    // Start the animation running 60 times per second
    const counter = setInterval(() => {
      frame++;
      // Calculate our progress as a value between 0 and 1
      // Pass that value to our easing function to get our
      // progress on a curve
      const progress = easeOutQuad(frame / totalFrames);
      // Use the progress value to calculate the current count
      const currentCount = Math.round(countTo * progress);

      // If the current count has changed, update the element
      if (this.number1 !== currentCount) {
        this.number1 = currentCount;
      }

      // If we’ve reached our last frame, stop the animation
      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  }

  animateCountUp2(toNumber) {

    // How long you want the animation to take, in ms
    const animationDuration = 2000;
    // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
    const frameDuration = 1000 / 32;
    // Use that to calculate how many frames we need to complete the animation
    const totalFrames = Math.round(animationDuration / frameDuration);
    // An ease-out function that slows the count as it progresses

    function easeOutQuad(t) {
      return t * (2 - t);
    }

    let frame = 0;
    const countTo = toNumber
    // Start the animation running 60 times per second
    const counter = setInterval(() => {
      frame++;
      // Calculate our progress as a value between 0 and 1
      // Pass that value to our easing function to get our
      // progress on a curve
      const progress = easeOutQuad(frame / totalFrames);
      // Use the progress value to calculate the current count
      const currentCount = Math.round(countTo * progress);

      // If the current count has changed, update the element
      if (this.number2 !== currentCount) {
        this.number2 = currentCount;
      }

      // If we’ve reached our last frame, stop the animation
      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  }

  animateCountUp3(toNumber) {

    // How long you want the animation to take, in ms
    const animationDuration = 2000;
    // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
    const frameDuration = 1000 / 32;
    // Use that to calculate how many frames we need to complete the animation
    const totalFrames = Math.round(animationDuration / frameDuration);
    // An ease-out function that slows the count as it progresses

    function easeOutQuad(t) {
      return t * (2 - t);
    }

    let frame = 0;
    const countTo = toNumber
    // Start the animation running 60 times per second
    const counter = setInterval(() => {
      frame++;
      // Calculate our progress as a value between 0 and 1
      // Pass that value to our easing function to get our
      // progress on a curve
      const progress = easeOutQuad(frame / totalFrames);
      // Use the progress value to calculate the current count
      const currentCount = Math.round(countTo * progress);

      // If the current count has changed, update the element
      if (this.number3 !== currentCount) {
        this.number3 = currentCount;
      }

      // If we’ve reached our last frame, stop the animation
      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  }

}



