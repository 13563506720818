import { Component, OnInit } from '@angular/core';
import { Section } from '../Section';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { NgwWowService } from 'ngx-wow';
@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent implements OnInit {
  check = faCheckCircle;
  section = Section;
  constructor(private wowService: NgwWowService) {
    this.wowService.init();
   }

  ngOnInit() {
  }

  continue(){
    Section.next()
  }

}
