import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PortfolioFormComponent } from './portfolio-form.component';
export class DocumentInputHelper {
    constructor(private portfolioForm: PortfolioFormComponent,
        private notificationService: ToastrService,
        private fb: FormBuilder) {
    }

    onChange(event) {

        let documentCount = this.documentCount();
        let files = event.target.files;
        if (files) {
            for (let file of files) {
                if (this.isDocumentSizeValide(file)) {
                    this.notificationService.warning("Each document size should be up to 5 MB");
                    return;
                }
                documentCount++
            }

            if (documentCount > 4) {
                this.notificationService.warning("You can attach up to 4 documents");
                return;
            }

            this.addDocument(files);
        }

        this.resetDocumentInput();
    }


    private isDocumentSizeValide(file: any) {
        return file.size && file.size > this.toMB(5);
    }


    private documentCount() {
        return this.portfolioForm.document.length;
    }


    private addDocument(files: any) {
        for (let file of files) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.portfolioForm.document.push(this.createItem({
                    name: file['name'],
                    file: e.target.result //Base64 string for preview image
                }));
            };
            reader.readAsDataURL(file);
        }
    }


    resetDocumentInput() {
        this.portfolioForm.documentInput.nativeElement.value = "";
    }


    private createItem(data): FormGroup {
        return this.fb.group(data);
    }


    private toMB(n) {
        return n * 1024 * 1024;
    }
}
