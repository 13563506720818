import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { faCoffee, faAngleLeft, faAlignRight } from '@fortawesome/free-solid-svg-icons';
import { Post } from '../../../models/Post';
import { PostService } from '../../../services/post.service'
import { NgwWowService } from 'ngx-wow';
@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {
  faCoffee = faCoffee;
  faAlignRight = faAlignRight;
  faAngleLeft = faAngleLeft;

  InstagramSliderConf: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: true,
    dots: false,
    nav: false,
    animateIn: 'bounceInRight',
    navSpeed: 700,
    autoplay: true,
    margin: 20,
    navText: [' <fa-icon [icon]="faAngleLeft"></fa-icon>', ' <fa-icon [icon]="faAlignRight"></fa-icon>'],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      740: {
        items: 1
      },
      940: {
        items: 3
      },
      1024:{
        items: 6
      },
      1200:{
        items: 6
      },
    },

  }
  postList: Array<Post> = [];

  constructor(private postService: PostService, private wowService: NgwWowService) {
    this.wowService.init();
  }

  ngOnInit(): void {
    this.postService.getPostList().subscribe(res => {
      this.postList = res;
    })
  }
  reset() {
    this.wowService.init();
  }

  redirectToPost(url){
    if(url){
      window.location.href = url;
    }
  }
}
