import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Section } from './Section';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CanDeactivate, Router } from '@angular/router';
import { JoinUsFormService } from '../services/join-us-form.service';
import { NgwWowService } from 'ngx-wow';
import { CanDeactivateGuardJoinus } from '../guards/deactivate-guard-joinus.service';
@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit, CanDeactivateGuardJoinus {
  cross = faTimes;
  modalRef: BsModalRef;
  section = Section;
  isCompleted = false;
  isCompletedObserver;
  forceDeactivate = false;
  constructor(private modalService: BsModalService,
    private router: Router,
    private joinusService: JoinUsFormService, private wowService: NgwWowService) {
    this.wowService.init();
    this.joinusService.reset();
    Section.reset();
  }

  ngOnInit(): void {
    this.isCompletedObserver = this.joinusService.isCompleted().subscribe(res => {
      this.isCompleted = res
    })
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  exit() {
    this.forceDeactivate = true
    this.close();
    this.goBack();
  }

  close() {
    this.modalRef.hide();
  }

  goBack() {
    this.router.navigate(["/m/home"]);
  }

  ngOnDestroy() {
    this.isCompletedObserver.unsubscribe()
  }


  @HostListener("window:beforeunload", ["$event"])
  unloadHandler(event: Event) {
    if (this.forceDeactivate || this.isCompleted || false) {
      return true;
    } else {
      event.returnValue = false;
    }
  }

  canDeactivate() {
    if (this.forceDeactivate || this.isCompleted) {
      return true;
    } else {
      return confirm('Are you sure you want to leave? You are only one step away from being a part of the clan.')
    }
  }



}

