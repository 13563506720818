import { Component, OnInit } from '@angular/core';
declare var $: any;
import { NgwWowService } from 'ngx-wow';
@Component({
  selector: 'app-community-guide-line',
  templateUrl: './community-guide-line.component.html',
  styleUrls: ['./community-guide-line.component.scss']
})
export class CommunityGuideLineComponent implements OnInit {

 
  constructor(private wowService: NgwWowService) { 
    this.wowService.init();
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    $(document).ready(function() {
    //   $(window).scroll(function (event) {
    //     var scroll = $(window).scrollTop();
    //     if($('.line-mt-htmwa').offset()){
    //       $('#line9').toggleClass('ln-border-width66',
    //       scroll >= $('.line-mt-htmwa').offset().top-300,
    //       );
    //      }
       
    //    if($('.line-mt-htmwq').offset()){
    //     $('#line10').toggleClass('ln-border-width66',
    //     scroll >= $('.line-mt-htmwq').offset().top-200,
    //     );
    //    }
    //     //add 'ok' class when div position match or exceeds else remove the 'ok' class.
        
     
    // });
   
    });
   
  //trigger the scroll
 
   
  }
  refresh(): void {
    window.location.reload();
}
ngOnDestroy(){
  $(window).scroll();
}
  reset() {
    this.wowService.init();
  }

}
