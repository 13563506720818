import { Injectable } from '@angular/core';
import { CommunityMember } from '../models/CommunityMember';
import { CoreTeamMember } from '../models/CoreTeamMember';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  coreTeamList: Array<CoreTeamMember>;
  communityMemberList: Array<CommunityMember>;

  constructor() {
    let member1 = new CoreTeamMember;
    member1.name = "Namrata"
    member1.designation = "Art Director"
    member1.image = "../../assets/images/t1.png"

    let member2 = new CoreTeamMember;
    member2.name = "Sohini"
    member2.designation = "Vibe Manager"
    member2.image = "../../assets/images/t2.png"

    let member3 = new CoreTeamMember;
    member3.name = "Kristel"
    member3.designation = "Social"
    member3.image = "../../assets/images/t3.png"
    this.coreTeamList = [member1, member2, member3]

    this.communityMemberList = [
      {
        designation: "Branding & Communication",
        name: "Jovina Fernandes",
        image: "../../assets/images/Jovina-Fernandes.png"
      },
      {
        designation: "Development Partner",
        name: "Sharmil Shah",
        image: "../../assets/images/Sharmil-Shah.png"
      },
      {
        designation: "Legal Aid",
        name: "Aditi Pawar",
        image: "../../assets/images/Aditi-Pawar.png"
      },
      {
        designation: "Finance",
        name: "Punit Sathiya",
        image: "../../assets/images/Punit-Satiya.png"
      },
      {
        designation: "UX Specialist",
        name: "Ishiyetaa Saxena",
        image: "../../assets/images/Ishiyetaa-Saxena.png"
      },
      {
        designation: "Tech Support",
        name: "Shoan Fernandes",
        image: "../../assets/images/Shoan-Fernandes.png"
      },
      {
        designation: "Shape Shifter",
        name: "Melissa Alva",
        image: "../../assets/images/Melissa-Alva.png"
      },
      {
        designation: "Technical Support",
        name: "Mohammad Ali",
        image: "../../assets/images/Mohammad-Ali.png"
      },
      {
        designation: "Operational Support",
        name: "Jeetendra Kumar",
        image: "../../assets/images/Jitendra-Prasad-(2).png"
      },
      {
        designation: "Brand Graphic Designer",
        name: "Rohan Haldankar",
        image: "../../assets/images/Rohan-Haldankar.png"
      },
      {
        designation: "Brand Photographer & Motion Graphic Designer",
        name: "Abdullah Usman",
        image: "../../assets/images/Abdullah-Usman.png"
      },
      {
        designation: "Editor",
        name: "Paras Bhardwaj",
        image: "../../assets/images/Paras-Bhardwaj.png"
      },
      {
        designation: "Illustration Artist",
        name: "Aftab Ahmed",
        image: "../../assets/images/Aftab-Ahmed.png"
      },
      {
        designation: "Illustration Artist",
        name: "Indrajeet Manjhi",
        image: "../../assets/images/Indrajeet-Manjhi.png"
      },
      {
        designation: "Illustration Artist",
        name: "Samarth Sharma",
        image: "../../assets/images/Samarth-Sharma.png"
      },
      {
        designation: "Network Resource",
        name: "Omkar Dhareshwar",
        image: "../../assets/images/Omkar-Dhareshwar.png"
      },
      {
        designation: "Network Resource",
        name: "Zain Siddiqui",
        image: "../../assets/images/Zain-Siddiqui (1).png"
      },
      {
        designation: "Project Manager - IT",
        name: "Bilal Raza Khan",
        image: "../../assets/images/Bilal-Khan.png"
      },
      {
        designation: "Sr. Project Manager - IT",
        name: "Ankita Vanju",
        image: "../../assets/images/Ankita-Vanju.png"
      },
      {
        designation: "Sr. Web Graphic Design",
        name: "Ravinder Rampelli",
        image: "../../assets/images/Ravinder-Rampelli.png"
      },
      {
        designation: "Quality Assurance - IT",
        name: "Prachi Kedar",
        image: "../../assets/images/Prachi-Kedar.png"
      },
      {
        designation: "Software Developer",
        name: "Mahesh Maurya",
        image: "../../assets/images/Mahesh-Maurya-03.png"
      },
      {
        designation: "Software Developer",
        name: "Lajari Dalvi",
        image: "../../assets/images/Lajari-Dalvi.png"
      },
      {
        designation: "Software Developer",
        name: "Pratik Korade",
        image: "../../assets/images/Pratik-Korade.png"
      },
      {
        designation: "Software Developer",
        name: "Muzammil Ahmed Ansari",
        image: "../../assets/images/Muzammil-Ansari.png"
      },
      {
        designation: "Creative Ideation",
        name: "Pratik Khatanhar",
        image: "../../assets/images/Pratik-Khatanhar.png"
      },
      {
        designation: "Illustration Artist",
        name: "Bhupender Kumar",
        image: "../../assets/images/Bhupender-Kumar.png"
      },
      {
        designation: "Illustration Artist",
        name: "Yogesh Baroliya",
        image: "../../assets/images/Yogesh-Baroliya.png"
      },
      {
        designation: "Sales Support",
        name: "Someshwar Vaidya",
        image: "../../assets/images/Someshwar-Vaidya.png"
      },
      {
        designation: "Creative Ideation",
        name: "Ankit Satra",
        image: "../../assets/images/Ankit-Satra.png"
      },
      {
        designation: "Creative Ideation",
        name: "Prachay Thakore",
        image: "../../assets/images/Prachay-Thakore.png"
      },
      {
        designation: "Operations & Execution ",
        name: "Deepak Parmar",
        image: "../../assets/images/Deepak-Parmar.png"
      },
      {
        designation: "Graphic Design",
        name: "Dinesh Pawar",
        image: "../../assets/images/Dinesh-Pawar.png"
      },
      {
        designation: "Operations and Creative Support",
        name: "Emerald Williams",
        image: "../../assets/images/Emerald-Williams.png"
      },
      {
        designation: "Content Creation",
        name: "Aditya Sharma",
        image: "../../assets/images/Aditya-Sharma.png"
      },
      {
        designation: "SEO & Digital",
        name: "Gavin Hendricks",
        image: "../../assets/images/Gavin-Hendricks.png"
      },
      {
        designation: "Business Advisory",
        name: "Vinda Dravid",
        image: "../../assets/images/Vinda-Dravid.png"
      },
      {
        designation: "Research and Operations ",
        name: "Venissa Alva",
        image: "../../assets/images/Venissa-Alva.png"
      },
      {
        designation: "Operations ",
        name: "Umulkiram Bhanpurawala",
        image: "../../assets/images/Umulkiram-Bhanpurawala.png"
      },
      {
        designation: "Operations ",
        name: "Kinjal Shah",
        image: "../../assets/images/Kinjal-Shah.png"
      },
      {
        designation: "Entertainment Head",
        name: "Yoda",
        image: "../../assets/images/Yoda.png"
      },
      {
        designation: "Head of Security",
        name: "Shifu",
        image: "../../assets/images/Shifu.png"
      },
      {
        designation: "Local Resident",
        name: "Ghost",
        image: "../../assets/images/Ghost.png"
      }
    ]

  }
}
