import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './main/home/home.component';
import { AboutUsComponent } from './main/info/about-us/about-us.component';
import { CommunityComponent } from './main/community/community.component';
import { OurServiceComponent } from './main/general/our-service/our-service.component';
import { OurStudioComponent } from './main/our-studio/our-studio.component';
import { JoinUsComponent } from './join-us/join-us.component';
import { InfoComponent } from './main/info/info.component';
import { FounderNoteComponent } from './main/info/founder-note/founder-note.component';
import { TeamComponent } from './main/info/team/team.component';
import { CommunityGuideLineComponent } from './main/general/community-guide-line/community-guide-line.component';
import { GeneralComponent } from './main/general/general.component';
import { ArtIndiaCompanyComponent } from './main/community/art-india-company/art-india-company.component';
import { IlaakaComponent } from './main/community/ilaaka/ilaaka.component';
import { IndianVyapaariComponent } from './main/community/indian-vyapaari/indian-vyapaari.component';
import { PartshaalaComponent } from './main/community/partshaala/partshaala.component';
import { DelayResolverService } from './resolvers/delay-resolver.service';
import { DeactivateGuardJoinusService } from './guards/deactivate-guard-joinus.service';
import { PrivacyPolicyComponent } from './main/privacy-policy/privacy-policy.component';



const routes: Routes = [
  { path: '', redirectTo: 'm', pathMatch: 'full' },
  
  {
    path: 'm', component: MainComponent, children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home', component: HomeComponent
      },
     
      {
        path: 'info', component: InfoComponent, children: [
          { path: '', redirectTo: 'about-us', pathMatch: 'full' },
          { path: 'about-us', component: AboutUsComponent, data: { animation: 'About' } },
          { path: 'founder-note', component: FounderNoteComponent, data: { animation: 'Founder' } },
          { path: 'team', component: TeamComponent, data: { animation: 'Team' } },
          { path: '**', redirectTo: 'about-us' }
        ]
      },
      {
        path: 'community', component: CommunityComponent, children: [
          { path: '', redirectTo: 'art-india-company', pathMatch: 'full' },
          { path: 'art-india-company', component: ArtIndiaCompanyComponent },
          { path: 'ilaaka', component: IlaakaComponent },
          { path: 'indian-vyapaari', component: IndianVyapaariComponent },
          { path: 'partshaala', component: PartshaalaComponent },
          { path: '**', redirectTo: 'art-india-company' }
        ]
      },
      {
        path: 'general', component: GeneralComponent, children: [
          { path: '', redirectTo: 'our-service', pathMatch: 'full' },
          { path: 'our-service', component: OurServiceComponent },
          { path: 'community-guideline', component: CommunityGuideLineComponent },
          { path: '**', redirectTo: 'our-service' }
        ]
      },
      {
        path:'privacy-policy', component:PrivacyPolicyComponent  },
      { path: 'our-studio', component: OurStudioComponent },
      { path: '**', redirectTo: 'home' }

    ]
  },
 
  {
    path: 'join-us',
    canDeactivate: [DeactivateGuardJoinusService],
    component: JoinUsComponent
  },
 
  { path: '**', redirectTo: 'm' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    enableTracing: true,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
