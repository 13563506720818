import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './main/home/home.component';
import { CommunityComponent } from './main/community/community.component';
import { JoinUsComponent } from './join-us/join-us.component';
import { AboutUsComponent } from './main/info/about-us/about-us.component';
import { OurServiceComponent } from './main/general/our-service/our-service.component';
import { OurStudioComponent } from './main/our-studio/our-studio.component';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './main/header/header.component';
import { FooterComponent } from './main/footer/footer.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InfoComponent } from './main/info/info.component';
import { FounderNoteComponent } from './main/info/founder-note/founder-note.component';
import { TeamComponent } from './main/info/team/team.component';
import { CoreTeamComponent } from './main/info/team/core-team/core-team.component';
import { FoundingCommunityComponent } from './main/info/team/founding-community/founding-community.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CreatorsComponent } from './main/home/creators/creators.component';
import { InstagramComponent } from './main/home/instagram/instagram.component';
import { GeneralComponent } from './main/general/general.component';
import { CommunityGuideLineComponent } from './main/general/community-guide-line/community-guide-line.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ArtIndiaCompanyComponent } from './main/community/art-india-company/art-india-company.component';
import { PartshaalaComponent } from './main/community/partshaala/partshaala.component';
import { IlaakaComponent } from './main/community/ilaaka/ilaaka.component';
import { IndianVyapaariComponent } from './main/community/indian-vyapaari/indian-vyapaari.component';
import { ParallaxDirective } from './parallax.directive';
import { GoWildDirective } from './go-wild.directive';
import { NgwWowModule } from 'ngx-wow';
import { ParallaxModule, ParallaxConfig } from 'ngx-parallax';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReactiveFormsModule } from '@angular/forms';
import { ReviewFormComponent } from './join-us/review-form/review-form.component';
import { BasicInfoFormComponent } from './join-us/basic-info-form/basic-info-form.component';
import { AdditionInfoFormComponent } from './join-us/addition-info-form/addition-info-form.component';
import { PortfolioFormComponent } from './join-us/portfolio-form/portfolio-form.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { AddressDirective } from './directives/address.directive';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { StatsComponent } from './main/stats/stats.component';
import { PrivacyPolicyComponent } from './main/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    OnlyNumberDirective,
    OnlyAlphabetsDirective,
    AddressDirective,
    AppComponent,
    HomeComponent,
    CommunityComponent,
    JoinUsComponent,
    AboutUsComponent,
    OurServiceComponent,
    OurStudioComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    InfoComponent,
    FounderNoteComponent,
    TeamComponent,
    CoreTeamComponent,
    FoundingCommunityComponent,
    CreatorsComponent,
    InstagramComponent,
    GeneralComponent,
    CommunityGuideLineComponent,
    ArtIndiaCompanyComponent,
    PartshaalaComponent,
    IlaakaComponent,
    IndianVyapaariComponent,
    ParallaxDirective,
    GoWildDirective,
    ReviewFormComponent,
    BasicInfoFormComponent,
    AdditionInfoFormComponent,
    PortfolioFormComponent,
    StatsComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgwWowModule,
    ParallaxModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right'
    }),
    HttpClientModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
