import { Injectable } from '@angular/core';
import { Founder } from '../models/Founder';

@Injectable({
  providedIn: 'root'
})
export class FounderService {

  founderList:Array<Founder> = [];
  constructor() { 
    let founder1 = new Founder()
    founder1.name = "Rishi";
    founder1.quote = `“I Have a Dream”` 
    founder1.quoteAuther = "Martin Luther King Jr."
    founder1.designation = "Vande Mataram," 
    founder1.image = "../../assets/images/founder.png"
    founder1.note =`<p>
    I’ve been a ‘people’s person’ for as long as I can remember. Despite a
    successful career in the event industry, I yearned to be in a space where I
    could physically make and see business take place. Meeting new talent,
    chasing a constantly evolving market, admiring business modules and thriving
    at networking within my industry was never just a hobby. It quickly became a
    way of life. So I took matters into my own hands and created the platform I
    wish I had access too when I first started out.
  </p>
  <p>
    Today, being able to be more than just a facilitator, but an enabler to
    businesses feels like things have come full circle. Not only do I get to do
    what I do best, but I’m also able to watch this community grow into the
    vision we started out with, which was to create a marketplace that
    celebrates the art of Vyapaar.
  </p>
  <p>
    Here’s to the spirit of entrepreneurship, in all its forms!
  </p>`

  let founder2 = new Founder()
    founder2.name = "max";
    founder2.quote = `“I Have a Dream”` 
    founder2.quoteAuther = "Martin Luther King Jr."
    founder2.designation = "Vande Mataram," 
    founder2.image = "../../assets/images/founder.png"
    founder2.note =`<p>
    I’ve been a ‘people’s person’ for as long as I can remember. Despite a
    successful career in the event industry, I yearned to be in a space where I
    could physically make and see business take place. Meeting new talent,
    chasing a constantly evolving market, admiring business modules and thriving
    at networking within my industry was never just a hobby. It quickly became a
    way of life. So I took matters into my own hands and created the platform I
    wish I had access too when I first started out.
  </p>
  <p>
    Today, being able to be more than just a facilitator, but an enabler to
    businesses feels like things have come full circle. Not only do I get to do
    what I do best, but I’m also able to watch this community grow into the
    vision we started out with, which was to create a marketplace that
    celebrates the art of Vyapaar.
  </p>
  <p>
    Here’s to the spirit of entrepreneurship, in all its forms!
  </p>`

  this.founderList = [founder1,founder2]
 
  }
}
