import { Injectable } from '@angular/core';
import { Creator } from '../models/Creator'
@Injectable({
  providedIn: 'root'
})
export class CreatorService {

  creatorList: Array<Creator> = []

  constructor() { 

    for (let index = 1; index <= 5; index++) {
      let creator:Creator = new Creator();
      creator.profile_photo = "../../assets/images/testimonial.png";
      creator.name = "Antara Bansal";
      creator.content = `I’ve just started working independently but by being part of
      a group that is known to have brilliant artists, people
      automatically perceived my work to be of the highest
      standard.`;
      creator.about = "Founder of The Claymation Store at Art India Company";
      this.creatorList.push(creator);
    }


  }
}
