export class JoinUsRequest {
    name: string;
    address: string;
    dob;
    email: string;
    ambition: Array<string>;
    creator_hub: string
    document: Array<Document>

    brand_name?: string
    business_goal?: string
    facebook?: string
    gender?: string
    instagram?: string
    nature_of_business?: string
    comment?: string
    social_media_other?: string
    ownership_type?: string
    phone_number?: string
    twitter?: string
    website_url?: string
}

class Document {
    name: string
    file: string
}