export class Joinus {
  address?= "";
  ambition;
  hub
  dateOfBirth?= "";
  email?= "";
  fullName?= "";
  gender?= "";
  tel?= "";
  natureOfBussiness?= "";
  businessGoal?= "";
  brandName?= "";
  ownershipType?= "";
  websiteOrPortfolioURL?= "";
  document?;
  instagramURL?= "";
  twitterURL?= "";
  facebookURL?= "";
  otherURL?= "";
  note?= "";
}
