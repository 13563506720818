import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgwWowService } from 'ngx-wow';
@Component({
  selector: 'app-our-studio',
  templateUrl: './our-studio.component.html',
  styleUrls: ['./our-studio.component.scss']
})
export class OurStudioComponent implements OnInit {

  Ourstudiobig: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    animateIn: 'fadeIn', // add this
    animateOut: 'fadeOut',
    smartSpeed: 1500,
    navSpeed: 700,
    autoplay: true,
    navText: [' <fa-icon [icon]="faAngleLeft"></fa-icon>', ' <fa-icon [icon]="faAlignRight"></fa-icon>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },

  }

  Ourstudiosmall: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    animateIn: 'fadeIn', // add this
    animateOut: 'fadeOut',
    autoplay: true,
    autoplayTimeout: 6000, 
    navText: [' <fa-icon [icon]="faAngleLeft"></fa-icon>', ' <fa-icon [icon]="faAlignRight"></fa-icon>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      },
    },

  }
  constructor(private wowService: NgwWowService) {
    this.wowService.init();
   }

  ngOnInit(): void {
  }

}
