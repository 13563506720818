export class NavBar {
    public navToggle = false;

    toggleNav() {
        this.navToggle = !this.navToggle;
    }

    closeNav() {
        this.navToggle = false;
    }
}
