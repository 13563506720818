import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { JoinUsRequest } from '../models/JoinUsRequest';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Post } from '../models/Post';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  private baseUrl: string;
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.api_url;
    this.httpOptions = { headers: this.headers };

  }

  getHeaders() {
    return this.httpOptions;
  }

  sendJoinUsForm(data: JoinUsRequest) {
    // return this.http.post(this.baseUrl + '/api/v1/join-us', data, this.getHeaders());
    // return this.http.post("https://tma-email-microservice-299493fcc681.herokuapp.com/join-us", data, this.getHeaders());
    return this.http.post("https://tma-email.onrender.com/join-us", data, this.getHeaders());
  }
  
  getInstagramPost() {
    return this.http.get(this.baseUrl + '/api/v1/instagram-post', this.getHeaders());
  }

}


