import { Component, OnInit } from '@angular/core';
import { Section } from '../Section';
import { FormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { JoinUsFormService } from '../../services/join-us-form.service';

@Component({
  selector: 'app-addition-info-form',
  templateUrl: './addition-info-form.component.html',
  styleUrls: ['./addition-info-form.component.scss']
})
export class AdditionInfoFormComponent implements OnInit {

  section = Section;
  additionalInfoForm = this.fb.group({
    ambition: this.fb.group({
      "Growing your Network": [false],
      "Sales and leads": [false],
      "Engage with your Audience": [false],
      "Professional Growth": [false]
    }),
    note: ['']
  });

  constructor(private fb: FormBuilder,
    private joinUsService: JoinUsFormService) { }

  ngOnInit() {
  }

  submit() {
    if (!this.additionalInfoForm.valid) {
      return
    }
    this.joinUsService.updateFormValues(this.additionalInfoForm.value);
    this.joinUsService.send();
  }

  get ambition() {
    return <FormGroup>this.additionalInfoForm.get('ambition');
  }

}
